import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate, useParams } from 'react-router';
import { localDb } from '../../sync-db';
import Header from '../../components/repository/header';
import Footer from '../../components/landpage/footer';
import { Fragment, useEffect, useState } from 'react';
import GuideCard from '../../components/repository/guide-card';
import { filterDataWithParams } from '../../helpers/data-filter';

const Machines = () => {
  const [guides, setguides] = useState()
  const [parts, setparts] = useState()
  const raw = useLiveQuery(async () => await localDb._guides.toArray())
  const _raw = useLiveQuery(async () => await localDb._machineParts.toArray())
  const { name } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const data = filterDataWithParams(_raw, {machine: name})
    setparts(data)
    return () => {
      setparts(null)
    };
  }, [_raw, name]);

  // useEffect(() => {
  //   if (parts && parts.length > 0) {
  //     const _data = []
  //     parts.forEach(part => _data.push(filterDataWithParams(raw, {machine: part})))
  //     setguides(_data)
  //     console.log(_data)
  //   }
  //   return () => {
  //     setguides(null)
  //   };
  // }, [guides, parts, raw]);

  return (
    <div className='w-screen h-screen'>
      <Header />
      <div className='flex flex-row mb-16'>
        <div className='lg:basis-80 basis-0 ' />
        <div className='grow flex flex-col lg:pt-0'>
          <div className='container py-8'>
            <span className='text-md font-black capitalize cursor-pointer' onClick={() => navigate('/repertory')}>guides</span>
            {name && <Fragment>
              <span className='mx-2 font-light text-gray-300'>&#10095;</span>
              <span className='text-md font-black capitalize cursor-pointer' onClick={() => navigate('/repertory')}>machines</span>
              <span className='mx-2 font-light text-gray-300'>&#10095;</span>
              <span className='text-md font-black capitalize'>{name}</span>
            </Fragment>}
          </div>
          <div className="w-full h-64 border relative bg-electrical bg-no-repeat mb-2 bg-cover">
            {/* <span className='text-white absolute text-xl capitalize bottom-4 right-8'>your go to repair guides repository</span> */}
          </div>
          <div className='container py-1 border-b mt-8'>
            <span className='text-lg font-black capitalize'>machines parts ({parts && parts.length})</span>
          </div>
          <div className='container grid grid-cols-4 gap-6 my-4'>
            {
              parts && parts.length > 0 && parts.map(({ name }, index) => <div key={index} className='px-4 bg-gray-100 h-16 flex justify-start items-center cursor-pointer hover:scale-105'>
                <span className='font-md capitalize'>
                  {name}
                </span>
              </div>)
            }
          </div>
          <div className='container py-2 border-b mt-8'>
            <span className='text-lg font-black capitalize'>guides ({guides ? guides.length : 0})</span>
          </div>
          <div className='container grid grid-cols-4 gap-4 my-4'>
            {guides && guides.length > 0 && guides.map((guide, index) => <GuideCard key={index} guide={guide} />)}
          </div>
        </div>
        <div className='lg:basis-80 basis-0' />
      </div>
      <Footer />
    </div>
  );
}

export default Machines;
