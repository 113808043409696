import React, { useState } from 'react'
import Logo from 'assets/repairhive logo/repairhaive-white icon.png'
import search from 'assets/images/search.svg'
import { useLocation, useNavigate } from 'react-router';
import { NavHashLink } from 'react-router-hash-link';
import Accountandbell from 'components/common/accountandbell';


const Header = () => {
  const navigate = useNavigate()

  const [showpop, setshowpop] = useState(false)
  const handleShowPopup = () => {
    setshowpop((prev => !prev))
  }

  const switchToAccountHandler = () => {
    // account switching logic according to the role stored in the cookie
    navigate('/author')
  }

  return (
    <div className='sticky top-0 h-20 shadow-md overflow-x-clip bg-[#242E42] flex flex-row items-center z-30'>
      <div className=' mx-auto w-[95%] lg:w-[80%]'>
        <div className='grow flex flex-row items-center'>
          <div className='basis-64 container'>
            <NavHashLink className='capitalize px-2 text-md cursor-pointer' smooth to="/#home">
              <img src={Logo} className='w-44 md:w-56 items-center' alt='logo' />
            </NavHashLink>
          </div>
          <div className='container grow flex flex-row justify-center'>
            <div className='rounded-full flex flex-row border items-center border-gray-300 h-12 w-3/4'>
              <div className='container basis-16 px-4'>
                <img src={search} />
              </div>
              <div className='container grow'>
                <input type='text' className='border-0 ring-0 outline-none text-white bg-[#242E42] w-5/6 px-8 tracking-wider' placeholder='Search knowledge base' />
              </div>
            </div>
          </div>
          <div className='container basis-96 flex flex-row justify-end items-center'>

            {/* <Accountandbell /> */}
            <span className='capitalize text-white cursor-pointer hover:text-blue' onClick={() => switchToAccountHandler()}>your account</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
