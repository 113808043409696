import React from 'react';
import Logo from 'assets/repairhive logo/Repairhove-main.png'
import _Logo from 'assets/repairhive logo/repairhaive-white-alt icon.png'
import __Logo from 'assets/repairhive logo/IC-white-logo 1.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavHashLink } from 'react-router-hash-link';
import Header from 'components/landpage/header-x';

const RequestDemo = () => {

  const handleSubmit = () => { }

  const formik = useFormik({
    initialValues: {
      email: '',
      fullNames: '',
      phoneNumber: '',
      company: ''
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required('Phone number is required'),
      fullNames: Yup.string().required('Fullnames are required'),
      email: Yup.string().email('Invalid email').required('Company email is required'),
      company: Yup.string().required('Company name is required')
    }),
    onSubmit: (values) => handleSubmit(values)
  })

  return (
    <div className='w-screen h-screen overflow-y-auto absolute'>
    <Header/>
     
      <div className='flex flex-row'>
        <div className='grow flex flex-row items-center'>
          <div className=' bg-white md:basis-2/3 overflow-hidden lg:basis-1/2'>
          <div className='w-[90%] lg:w-[80%] xl:w-[60%]  mx-auto flex flex-col justify-center space-y-4'>
            <h3 className='text-blue text-3xl text-center lg:text-left font-semibold leading-6 tracking-wider my-2'>Get a demo</h3>
            <h3 className='font-semibold text-4xl text-center lg:text-left leading-10 tracking-wider py-4'>See what <span className='text-blue'>RepairHive</span> can do for you</h3>
            <h3 className='text-gray-300 text-center lg:text-left'>Tell us a little about yourself and we'll connect you with a RepairHive expert who can share more about the product and answer any questions you have.</h3>
            <div className='grid grid-cols-4'>
              <div className='col-span-4'>
                <div className='my-4'>
                  <input
                    name='email'
                    type='text'
                    className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    placeholder='Your work email'
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className='my-4'>
                  <input
                    name='fullNames'
                    type='text'
                    className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    placeholder='Full names'
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className='my-4'>
                  <input
                    name='phoneNumber'
                    type='text'
                    className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    placeholder='Phone number'
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className='my-4'>
                  <input
                    name='company'
                    type='text'
                    className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    placeholder='Company'
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <button className='bg-blue w-full text-white px-6 capitalize py-4 rounded-sm border-none text-md'>request demo</button>
              </div>
            </div>
          </div>
          </div>
          <div className='md:basis-1/3 lg:basis-1/2  bg-demo bg-no-repeat bg-cover object-none h-[calc(100vh-64px)]' />
        </div>
      </div>
    </div>
  );
}

export default RequestDemo;
