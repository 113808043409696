import Accountandbell from "./common/accountandbell"

const HeaderNav = ({user, activeRoute, child, switchRoute}) => {

  


  if (user)
    return (
      <nav className='w-full bg-white h-full'>
        <div className='px-2 md:px-6 lg:px-6 py-[1.5px] md:py-3 lg:py-3 mx-auto'>
          <div className='flex flex-row items-center'>
            <div className='flex items-center flex-row basis-96'>
              {activeRoute && (
                <h1 className='text-[20px] pt-2 pb-4 text-gray-600 tracking-widest'>
                  {activeRoute}
                </h1>
              )}
              {child && (
                <h1 className='text-[20px] pt-2 pb-4 text-gray-600 tracking-widest ml-2 capitalize'>{` ⟩ ${child}`}</h1>
              )}
            </div>
           <Accountandbell user={user}/>
          </div>
        </div>
      </nav>
    )
}

export default HeaderNav
