import React from 'react'
import Model from '../../common/model'
import Button from '../../common/button'
import Select from 'react-select'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {localDb} from 'sync-db'
import {toast} from 'react-toastify'
import axios from 'axios'

const options = [
  {value: 'author', label: 'Author'},
  {value: 'engineer', label: 'Engineer'},
  {value: 'machine owner', label: 'Machine owner'}
]

const AddUserModal = ({userInitialvalues, isEdit, handleclose}) => {
  const handleUserRegisteration = async (data) => {
    try {
      await localDb._users.get({email: data.email}).then(async (res) => {
        const notification = toast.loading('Registering user ...')
        if (!res) {
          await axios
            .post(`${process.env.REACT_APP_API}/auth/register`, {...data})
            .then(async () => {
              await localDb._users
                .add({
                  ...data
                })
                .then(() => {
                  toast.update(notification, {
                    render: 'User added successfully',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                  })
                })
            })
            .catch(() => {
              toast.update(notification, {
                render: 'Error occured while adding user',
                type: 'error',
                isLoading: false,
                autoClose: 3000
              })
            })
        } else {
          toast.update(notification, {
            render: 'User already exists',
            type: 'error',
            isLoading: false,
            autoClose: 3000
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: userInitialvalues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      role: Yup.string()
        .oneOf(['author', 'engineer', 'machine owner'])
        .required('Role is required'),
      password: Yup.string().required('Password is required')
    }),
    onSubmit: (values) => handleUserRegisteration(values)
  })

  return (
    <Model handleClose={handleclose}>
      <div className='py-12 w-full grow z-50 flex flex-col items-center justify-center rounded-md'>
        <div className='flex flex-row w-3/4 items-center'>
          <div className='basis-32'>
            <label
              htmlFor='firstName'
              className='block text-md text-gray-800 dark:text-gray-200 capitalize'
            >
              first name
            </label>
          </div>
          <div className='grow'>
            <input
              name='firstName'
              type='text'
              className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
              placeholder='John'
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.firstName}
              </div>
            ) : null}
          </div>
        </div>

        <div className='flex flex-row w-3/4 items-center mt-5'>
          <div className='basis-32'>
            <label
              htmlFor='username'
              className='block text-md text-gray-800 dark:text-gray-200 capitalize'
            >
              last name
            </label>
          </div>
          <div className='grow'>
            <input
              name='lastName'
              type='text'
              className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
              placeholder='mac'
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.lastName}
              </div>
            ) : null}
          </div>
        </div>
        <div className='flex flex-row w-3/4 items-center mt-5'>
          <div className='basis-32'>
            <label
              htmlFor='username'
              className='block text-md text-gray-800 dark:text-gray-200 capitalize'
            >
              email
            </label>
          </div>
          <div className='grow'>
            <input
              name='email'
              type='text'
              className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
              placeholder='example@example.com'
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.email}
              </div>
            ) : null}
          </div>
        </div>
        <div className='flex flex-row w-3/4 items-center mt-5'>
          <div className='basis-32'>
            <label
              htmlFor='role'
              className='block text-md text-gray-800 dark:text-gray-200 capitalize'
            >
              role
            </label>
          </div>
          <div className='grow'>
            <Select
              options={options}
              onChange={({value}) => formik.setFieldValue('role', value)}
              placeholder='Role'
              name='role'
              className='block w-full
   focus:outline-none focus:ring focus:ring-opacity-40'
              isSearchable={false}
            />
            {formik.touched.role && formik.errors.role ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.role}
              </div>
            ) : null}
          </div>
        </div>
        <div className='flex flex-row w-3/4 items-center mt-5'>
          <div className='basis-32'>
            <label
              htmlFor='password'
              className='block text-md text-gray-800 dark:text-gray-200 capitalize'
            >
              password
            </label>
          </div>
          <div className='grow'>
            <input
              name='password'
              type='password'
              className='block w-full px-4 py-2 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
              placeholder='password'
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.touched.email && formik.errors.password ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.password}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <footer className='flex justify-end basis-16 p-4'>
        <Button onClick={() => formik.handleSubmit()}>Add user</Button>
        <button
          className='ml-3 rounded-sm px-3 py-1 hover:bg-gray-300 focus:shadow-outline focus:outline-none'
          onClick={() => handleclose(false)}
        >
          Cancel
        </button>
      </footer>
    </Model>
  )
}

export default AddUserModal
