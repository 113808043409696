import {useState} from 'react'
import {useLiveQuery} from 'dexie-react-hooks'
import {localDb} from '../../sync-db'
import timer from 'assets/images/timer.svg'
import options from 'assets/images/options.svg'
import {formatDate} from '../../helpers/data-format'
import {capitalizeFirstLetter} from '../../helpers/capitalize-first-letter'
import {toast} from 'react-toastify'
import axios from 'axios'

const Guides = ({setactiveRoute, setchildroute}) => {
  const [toggleActions, settoggleactions] = useState(false)
  const [singleGuide, setsingleGuide] = useState()

  const guides = useLiveQuery(async () => await localDb._guides.toArray())

  const actionHandler = (action, guide) => {
    const {id} = guide
    switch (action) {
      case 'delete':
        deleteHandler(id)
        break
      case 'edit':
        setactiveRoute('Guide')
        setchildroute('edit')
        localStorage.setItem('guide-edit-id', id)
        break
    }
    settoggleactions(false)
  }

  const deleteHandler = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API}/guide/?id=${id}`)
      .then(async () => {
        await localDb._guides
          .where({id: id})
          .delete()
          .then(() => {
            toast.success('Guide deleted successfully', {autoClose: 3000})
          })
      })
      .catch((err) => {
        toast.error('Error occured while deleting guide', {autoClose: 3000})
      })
  }

  return (
    <div className='w-full h-[calc(100vh-65px)] p-3'>
      {!singleGuide && (
        <div className='w-full h-full bg-white overflow-y-auto relative'>
          <div className='w-full border-b border-gray-200 py-4 px-8 sticky top-0 bg-white z-10'>
            <span className='text-gray-600 text-sm font-semibold capitalize'>
              all guides ({guides && guides.length})
            </span>
          </div>
          {guides && guides.length > 0 ? (
            <div className='grid grid-cols-5 gap-4 px-8 py-4'>
              {guides.map((guide, index) => (
                <div
                  key={index}
                  className='border pb-4 border-gray-200 h-64 rounded-md flex flex-col gap-2'
                >

                  {/* image */}
                  <div className='basis-4/7 overflow-hidden'>
                    <img
                      src={guide.featuredimage}
                      className='rounded-t-md w-full object-contain'
                      onClick={() => setsingleGuide(guide)}
                    />
                  </div>

                  <div
                    className='text-md px-4 basis-2/7'
                    onClick={() => setsingleGuide(guide)}
                  >
                    <span className='line-clamp-2 h-12 text-md font-semibold text-gray-600 cursor-pointer'>
                      {capitalizeFirstLetter(guide.title)}
                    </span>
                  </div> 

                   <div className='px-4 flex basis-1/7'>
                    <img src={timer} className='w-5 basis-0' />
                    <span className='text-gray-500 capitalize grow text-sm line-clamp-1 mx-1'>
                      {formatDate(guide.createdAt)}
                    </span>

                    <div className='container relative w-fit'>
                      <img
                        src={options}
                        className='cursor-pointer w-4'
                        onClick={() => settoggleactions(index)}
                      />
                      <ul
                        className={`absolute z-10 shadow-md bg-white py-3 ${
                          toggleActions !== index && `hidden`
                        }`}
                      >
                        <li
                          className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm'
                          onClick={() => actionHandler('edit', guide)}
                        >
                          edit
                        </li>
                        <li
                          className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm'
                          onClick={() => actionHandler('delete', guide)}
                        >
                          delete
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              ))}
            </div>
          ) : (
            <div className='w-full h-full flex justify-center items-center text-gray-600 font-semibold capitalize'>
              No guides found
            </div>
          )}
        </div>
      )}
      {singleGuide && <SingleGuide guide={singleGuide} setsingleGuide={setsingleGuide} />}
    </div>
  )
}

export default Guides

const SingleGuide = ({guide, setsingleGuide}) => {
  return (
    guide && (
      <div className='w-full h-full bg-white overflow-y-auto relative'>
        <div className='flex flex-row w-full border-b border-gray-200 py-4 px-8 sticky top-0 bg-white z-10'>
          <div className='basis-1/2'>
            <span className='text-gray-600 text-sm font-semibold capitalize tracking-wider'>
              {guide.title}
            </span>
          </div>
          <div className='basis-1/2 text-right'>
            <span
              className='text-blue font-semibold capitalize cursor-pointer'
              onClick={() => setsingleGuide(null)}
            >
              all guides
            </span>
          </div>
        </div>
        <div className='py-4 px-8'>
          <p className='tracking-wide' dangerouslySetInnerHTML={{__html: guide.content}} />
        </div>
      </div>
    )
  )
}

{
  /* <div  className='border border-gray-200 rounded-md  grid grid-rows-6 gap-2 rounded-b-md'>
<div className='row-span-4 cursor-pointer' onClick={() => setsingleGuide(guide)}>
  <img src={guide.featuredimage} className='h-full w-full rounded-t-md' />
</div>
<div className='row-span-2 grid grid-row-4 gap-2 px-2'>
  <div className='row-span-3 text-md' onClick={() => setsingleGuide(guide)}>
    <span className='line-clamp-2 text-md font-semibold text-gray-600 cursor-pointer'>{capitalizeFirstLetter(guide.title)}</span>
  </div>
  <div className='grid grid-cols-6 gap-4 reveala'>
    <div className='col-span-5 cursor-pointer' onClick={() => settoggleactions(null)}>
      <span className='line-clamp-1 flex flex-row items-center'>
        <img src={timer} className='w-4' />
        <span className='text-gray-500 capitalize text-sm line-clamp-1 mx-1'>{formatDate(guide.createdAt)}</span>
      </span>
    </div>
    <div className='container relative'>
      <img src={options} className='cursor-pointer w-8' onClick={() => settoggleactions(index)} />
      <ul className={`absolute z-10 shadow-md bg-white py-3 ${toggleActions !== index && `hidden`}`}>
        <li className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm' onClick={() => actionHandler('edit', guide)}>edit</li>
        <li className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm' onClick={() => actionHandler('delete', guide)}>delete</li>
      </ul>
    </div>
  </div>
</div>
</div> */
}
