import logo from 'assets/repairhive logo/repairhaive-white icon.png'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router'
import Repo from 'assets/images/repo.svg'

const SideNav = ({ routes, switchRoute, route, childRouteSwitch, childRoute }) => {
  const navigate = useNavigate()

  const logoutHandler = () => {
    Cookies.remove(process.env.REACT_APP_COOKIE_KEY)
    navigate('/auth', { replace: true })
  }

  const mainRouteSwitcher = (route, children) => {
    if (!children.includes(childRoute)) {
      childRouteSwitch('')
    }
    switchRoute(route)
  }
  return (
    <nav
      id='sidenav-8'
      className="left-0 top-0 z-[1035] h-full w-60 -translate-x-full overflow-hidden bg-[#242E42] shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
      data-te-sidenav-init
      data-te-sidenav-hidden='false'
      data-te-sidenav-position='absolute'
      data-te-sidenav-accordion='true'
    >
      <div className='mb-4 flex items-center justify-center border-b border-gray-100 py-12'>
        <img className='h-5/6 w-5/6 cursor-pointer' src={logo} alt='logo' draggable='false' onClick={() => navigate('/')} />
      </div>
      <ul className='relative m-0 list-none  pb-12' data-te-sidenav-menu-ref>
        {(routes && routes.length > 0) &&
          routes.map(({ label, icon, children }, index) => (
            <li className='relative' key={index}>
              <div className={`hover:bg-blue py-4 flex cursor-pointer items-center truncate px-6 text-[0.9rem] text-white ${route === label && `border-l-4 border-blue`}`} onClick={() => mainRouteSwitcher(label, children)}>
                <span className={`mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:text-gray-400  ${route === label && `-ml-1`}`}>
                  <img className='w-5' src={icon} alt='' />
                </span>
                <span className='text-sm'>{label}</span>
              </div>
              {
                children.length > 0 && <ul className={`m-0 list-none px-1 ${route === label ? `display-block` : `hidden`}`}>
                  {children.map((child, index) => <li key={index} className={`hover:bg-blue/40 ml-6 pl-8 relative py-2 capitalize cursor-pointer  text-white text-sm ${childRoute === child && `border-l-4  border-blue`}`} onClick={() => childRouteSwitch(child)}><span className='-ml-[4px]'>{child}</span></li>)}
                </ul>
              }
            </li>
          ))}
        <li className='relative'>
          <div className={`hover:bg-blue py-4 flex cursor-pointer items-center truncate px-6 text-[0.9rem] text-white`} onClick={() => navigate('/repertory')}>
            <span className='mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:text-gray-400'>
              <img src={Repo} className='w-5'  alt='' />
            </span>
            <span className='text-sm capitalize'>repertory</span>
          </div>
        </li>
      </ul>
      {/* <div className='bottom-0 absolute text-center w-full border
       cursor-pointer py-4 capitalize border-blue rounded-tl-lg rounded-tr-lg text-blue' onClick={() => logoutHandler()}>log out
       </div> */}
    </nav>
  )
}

export default SideNav
