import { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import Select from 'react-select'
import { toast } from 'react-toastify';
import { localDb } from '../../sync-db'
import { useLiveQuery } from 'dexie-react-hooks'
import Cookies from 'js-cookie';
import axios from 'axios'
import FeaturedImage from './components/featured-image';
import { useFormik } from 'formik';
import * as Yup from 'yup'

const CreateGuide = ({ edit = false }) => {
  const [showmodal, setshowmodal] = useState(false)
  const [isedit, setisedit] = useState(false)
  const [userId, setuserId] = useState('');
  const [faults, setfaults] = useState()
  const [machineparts, setmachineparts] = useState()
  const [featuredimage, setfeaturedimage] = useState();
  const editorRef = useRef(null)

  const guides = useLiveQuery(async () => await localDb._guides.toArray())
  const medias = useLiveQuery(async () => await localDb._medias.toArray())
  const _faults = useLiveQuery(async () => await localDb._faults.toArray())
  const _machineparts = useLiveQuery(async () => await localDb._machineParts.toArray())

  const submitHandler = async data => {
    try {
      if (!editorRef.current.getContent()) {
        return toast.error("Content is not available")
      }
      if (!featuredimage) {
        return toast.error("Featured image is required")
      }
      await localDb._guides.get({ title: data.title }).then(async res => {
        const notification = toast.loading('Creating guide ...')
        if (!res) {
          data['content'] = editorRef.current.getContent()
          data['featuredimage'] = featuredimage
          await axios.post(`${process.env.REACT_APP_API}/guide/${userId}`, { ...data }).then(async ({ data }) => {
            const { status, ...rest } = data
            await localDb._guides.add({
              ...rest
            }).then(() => {
              formik.resetForm()
              setfeaturedimage(null)
              editorRef.current.setContent('');
              toast.update(notification, { render: "Guide added successfully", type: "success", isLoading: false, autoClose: 3000 });
            })
          }).catch((error) => {
            toast.update(notification, { render: "Error occured while creating guide", type: "error", isLoading: false, autoClose: 3000 });
          })
        } else {
          toast.update(notification, { render: "Guide with the same title already exists", type: "error", isLoading: false, autoClose: 3000 });
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const editHandler = async data => {
    try {
      const notification = toast.loading('Editing guide type ...')
      await axios.put(`${process.env.REACT_APP_API}/guide/fault/?id=${data.id}`, { ...data }).then(async () => {
        const { id, ...rest } = data
        await localDb._guides.update(id, {
          ...rest
        }).then(() => {
          cauncelEditHandler()
          toast.update(notification, { render: "Guide updated", type: "success", isLoading: false, autoClose: 3000 });
        })
      }).catch(() => {
        toast.update(notification, { render: "Error occured while updating guide", type: "error", isLoading: false, autoClose: 3000 });
      })
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      fault: '',
      machine: ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      fault: Yup.string().required(),
      machine: Yup.string().required()
    }),
    onSubmit: (values) => isedit ? editHandler(values) : submitHandler(values)
  })

  // useEffect(() => {
  //   const _user = Cookies.get(process.env.REACT_APP_COOKIE_KEY)
  //   if (_user) {
  //     const { id } = JSON.parse(_user)
  //     setuserId(id)
  //     const editId = localStorage.getItem('guide-edit-id')
  //     if (editId && guides && guides.length > 0) {
  //       setisedit(edit)
  //       const guide = guides.filter(g => g.id === editId)[0]
  //       formik.setFieldValue('title', guide.title)
  //       formik.setFieldValue('fault', guide.fault)
  //       formik.setFieldValue('machine', guide.machine)
  //       setfeaturedimage(guide.featuredimage)
  //       // editorRef.current.setContent(guide.content);
  //     }
  //   }
  //   return () => { };
  // }, [edit, featuredimage, editorRef, formik]);

  useEffect(() => {
    if (_faults && _faults.length > 0) {
      setfaults(_faults.map(({ name }) => ({ label: name, value: name })))
    }
    if (_machineparts && _machineparts.length > 0) {
      setmachineparts(_machineparts.map(({ name }) => ({ label: name, value: name })))
    }
    return () => { };
  }, [_faults, _machineparts]);

  return (
    <>
      <div className='h-auto flex flex-row p-4 w-full'>
        <div className='basis-2/3 flex flex-col h-full'>
          <div className='basis-8'>
            <div className='container'>
              <h3 className='text-gray-600 text-md  capitalize pb-2'>title</h3>
              <input
                type="text"
                name="title"
                className={`block w-full px-4 py-2 mt-1 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 ${formik.touched.title && formik.errors.title && `border border-redshade`}`}
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder="Title" />
            </div>
          </div>
          <div className='flow h-full w-full relative overflow-auto mt-5'>
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue='<p>This is the initial content of the editor.</p>'
              init={{
                height: '100%',
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                  'preview',
                  'fullscreen',
                  'save'
                ],
                toolbar:
                  'undo redo | formatselect fontsize fontstyle | ' +
                  'bold italic backcolor | alignleft aligncenter list' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | font | preview | fullscreen',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                resize: false
              }}
            />
          </div>
        </div>
        <div className='basis-1/3 pl-5'>
          <div className='container flex flex-row sticky top-5 justify-end'>
            <button className='bg-blue rounded-md py-2 px-4 w-2/3 text-white capitalize text-center' type='submit'
              onClick={() => formik.handleSubmit()}>
              {isedit ? `Save changes` : `publish`}
            </button>
          </div>
          <div className='container mt-5'>
            <h3 className='text-gray-600 text-md  capitalize pb-2'>fault</h3>
            <Select options={faults} value={formik.values.fault} onChange={({ value }) => formik.setFieldValue('fault', value)} placeholder="Fault" name='fault' className={`block w-full
   focus:outline-none focus:ring focus:ring-opacity-40 ${formik.touched.fault && formik.errors.fault && `border border-redshade`}`} isSearchable={true} />
          </div>
          <div className='container mt-5'>
            <h3 className='text-gray-600 text-md  capitalize pb-2'>machine / machine part</h3>
            <Select options={machineparts} onChange={({ value }) => formik.setFieldValue('machine', value)} placeholder="Machine / Machine part" name='machine' className={`block w-full
   focus:outline-none focus:ring focus:ring-opacity-40 ${formik.touched.machine && formik.errors.machine && `border border-redshade`}`} isSearchable={true} />
          </div>
          <div className='container my-5 relative'>
            <h3 className='text-gray-600 text-md  capitalize pb-2'>featured image</h3>
            <div className={`w-full h-32 rounded-md border border-dotted flex justify-center items-center cursor-pointer ${formik.touched.fault && formik.errors.fault ? `border-redshade` : `border-gray-600`}`} onClick={() => setshowmodal(true)}>
              {featuredimage ? <img src={featuredimage} className='absolute h-28' /> : <span className='text-gray-400 text-3xl font-semibold'>+</span>}
            </div>
          </div>
          <div className='container'>
            <h3 className='text-gray-600 text-md capitalize pb-2'>media</h3>
            <div className='overflow-y-auto flex flex-wrap bg-white h-[250px]'>
              {
                medias && medias.length > 0 ? medias.map(({ name, url }, index) => <div key={index} className='basis-1/3 h-[100px] relative cursor-pointer mb-4'>
                  <img
                    className='p-2 absolute h-[150px]'
                    src={url}
                    alt={name}
                  />
                </div>) : <div className='h-full w-full flex justify-center items-center font-bold'>No media</div>
              }
            </div>
          </div>
        </div>
      </div>
      {showmodal && <FeaturedImage handleClose={() => setshowmodal(false)} setfeaturedimage={setfeaturedimage} featuredimage={featuredimage} />}
    </>
  )
}

export default CreateGuide
