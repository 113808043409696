import Cookies from 'js-cookie'
import {Navigate} from 'react-router-dom'

function Protected({children}) {
  const authenticatedUser = () => {
    const userCookie = Cookies.get(process.env.REACT_APP_COOKIE_KEY)
    if (userCookie) {
      const obj = JSON.parse(userCookie)
      if (obj) {
        return obj
      } else {
        return false
      }
    } else {
      return false
    }
  }
  if (!authenticatedUser) {
    return <Navigate to='/auth' replace />
  }
  return children
}
export default Protected
