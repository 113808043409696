import Dexie from 'dexie';

export const localDb = new Dexie('ubl-db');
localDb.version(31).stores({
  _user: 'email',
  _guides: 'id, title',
  _users: '++id, email',
  _medias: '++id, name',
  _machineParts: 'id, name',
  _machines: 'id, name',
  _departments: 'id, name',
  _faults: 'id, name',
});
