const Tab = ({options, activeOption, switchOption}) => {
  return (
    <>
      <ul className='mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0'>
        {options &&
          options.map((t, index) => (
            <li key={index} className='flex-auto text-center'>
              <button
                className={`my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent ${
                  activeOption === t && `border-primary text-primary`
                }`}
                data-te-toggle='pill'
                onClick={() => switchOption(t)}
              >
                {t}
              </button>
            </li>
          ))}
      </ul>
    </>
  )
}

export default Tab
