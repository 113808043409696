import dashboard from 'assets/images/dashboard.svg'
import media from 'assets/images/video-player.svg'
import settings from 'assets/images/cog.svg'
import users from 'assets/images/user.svg'
import create_guide from 'assets/images/create_guide.svg'
import guides from 'assets/images/guides.svg'

const authorsNavRoutes = [
  { label: 'Dashboard', icon: dashboard, children: [] },
  { label: 'Create Guide', icon: create_guide, children: [] },
  { label: 'Guides', icon: guides, children: [] },
  { label: 'Media', icon: media, children: [] },
  { label: 'Machines', icon: settings, children: ['machines', 'machine parts', 'faults', 'departments'] },
  { label: 'Users', icon: users, children: [] },
  // { label: 'Settings', icon: settings, children: [] }
]

export default authorsNavRoutes
