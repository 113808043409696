import Button from '../common/button'
import Tab from '../common/tab'
import search from 'assets/images/search.svg'
import grid from 'assets/images/grid.svg'
import menu from 'assets/images/menu.svg'
import add_rounded from 'assets/images/add-rounded.svg'
import { useEffect, useState } from 'react'
import Card from '../common/media-card'
import AddMediaModal from './components/add-media-modal'
import { localDb } from '../../sync-db'
import { useLiveQuery } from 'dexie-react-hooks'

const options = ['all', 'image', 'video', 'document']

const Media = () => {
  const [showModal, setShowModal] = useState(false)
  const [filteredMedia, setfilteredMedia] = useState([]);
  const [activeOption, setActiveOption] = useState()

  const medias = useLiveQuery(async () => await localDb._medias.toArray())

  const filterMediaByType = param => {
    if (medias) {
      const _filteredMedia = param === 'all' ? setfilteredMedia(medias) : medias.filter(({ type }) => type.toLowerCase() === param.toLowerCase())
      if (_filteredMedia) {
        setfilteredMedia(_filteredMedia)
      }
    }
  }

  useEffect(() => {
    setActiveOption(options[0])
    return () => { }
  }, [])

  useEffect(() => {
    filterMediaByType(activeOption)
    return () => { };
  }, [medias, activeOption]);

  return (
    <>
      {showModal && <AddMediaModal handleClose={setShowModal} />}
      <div className='h-[calc(100vh-145px)] p-4 w-full'>
        <div className='w-full h-full flex flex-col bg-white px-8 pt-4 pb-3'>
          <div className='flex justify-between items-center basis-5'>
            <div className='basis-5/6 flex justify-start'>
              <Tab options={options} activeOption={activeOption} switchOption={setActiveOption} />
            </div>
            <div className='basis-1/6 justify-between flex flex-row'>
              <button>
                <img alt='' src={search} />
              </button>
              <button>
                <img alt='' src={grid} />
              </button>
              <button>
                <img alt='' src={menu} />
              </button>
              <Button onClick={() => setShowModal(true)}>
                <img alt='' src={add_rounded} />
              </Button>
            </div>
          </div>
          <div className='grow h-full w-full inline-block overflow-y-auto'>
            {/* <h1 className='text-[20px] pt-2 pb-4 text-gray-600 tracking-widest'>March 10, 2020</h1> */}
            <div className='w-full flex flex-row flex-wrap'>
              {
                filteredMedia && filteredMedia.length > 0 ? filteredMedia.map(({ name, url }, index) => <div key={index} className='basis-1/4 h-60 relative mb-2'>
                  <Card name={name} url={url}/>
                </div>) : <div className='bg-warning-300 text-white font-semibold px-3 py-4'>
                  <h3 className='capitalize'>No media found</h3>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Media
