import React, { useState } from 'react'
import Logo from 'assets/repairhive logo/Repairhove-main.png'

import { useLocation, useNavigate } from 'react-router';
import { NavHashLink } from 'react-router-hash-link';


const Header = () => {
  const navigate = useNavigate()

  const [showpop, setshowpop] = useState(false)
  const handleShowPopup = () => {
    setshowpop((prev => !prev))
  }

  return (
    <div className='sticky top-0 h-16 shadow-md overflow-x-clip bg-white flex flex-row items-center z-30'>
      <div className=' mx-auto w-[95%] lg:w-[80%] '>
        <div className='grow flex flex-row items-center'>
          <NavHashLink className='capitalize px-2 text-md cursor-pointer' smooth to="/#home">
            <img src={Logo} className='w-44 md:w-56 items-center' alt='logo' />
          </NavHashLink>

          <button onClick={handleShowPopup} className='w-fit ml-auto'>
            {showpop ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 lg:hidden">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>}
          </button>
          <div className={`absolute right-0 top-14 w-screen ${showpop ? "bg-[black]/80 backdrop-blur" : ""}  overscroll-none transition-all `}>
            <div className={`ml-auto lg:hidden h-screen  bg-white ${showpop ? "" : "translate-x-full"} transition-all flex flex-col md:w-[40%] w-[70%] shadow-md`}>
              <button className={`bg-blue text-white px-6 ${useLocation().pathname === "/request-demo" && "hidden"} capitalize py-3 rounded-sm border-none`} onClick={() => navigate('/request-demo')}>request demo</button>

              <ul className='flex flex-col'>
                <NavHashLink className='capitalize px-4 py-2 text-right text-md w-full' smooth to="/#home" onClick={handleShowPopup}>Home</NavHashLink>
                <NavHashLink className='capitalize px-4 py-2 text-right text-md w-full' smooth to="/#features" onClick={handleShowPopup}>Features</NavHashLink>
                <NavHashLink className='capitalize px-4 py-2 text-right text-md w-full' smooth to="/#why-us" onClick={handleShowPopup}>Why us</NavHashLink>
                <NavHashLink className='capitalize px-4 py-2 text-right text-md w-full' smooth to="/#contact-us" onClick={handleShowPopup}>Contact us</NavHashLink>
              </ul>


              <button className='border border-blue px-4 py-2 capitalize text-blue rounded-sm mx-4' onClick={() => navigate('/auth')}>sign in</button>
            </div>
          </div>


          <div className='grow w-full hidden lg:flex justify-end py-3'>
            <ul className='flex flex-row items-center'>
              <NavHashLink className='capitalize px-2 text-md cursor-pointer' smooth to="/#home" activeStyle={{ color: '#2ABEC3' }}>Home</NavHashLink>
              <NavHashLink className='capitalize px-2 text-md' smooth to="/#features">Features</NavHashLink>
              <NavHashLink className='capitalize px-2 text-md' smooth to="/#why-us">Why us</NavHashLink>
              <NavHashLink className='capitalize px-2 text-md' smooth to="/#contact-us">Contact us</NavHashLink>
            </ul>
            <button className='border border-blue px-6 py-3 capitalize text-blue rounded-sm mx-4' onClick={() => navigate('/auth')}>sign in</button>
            <button className={`bg-blue text-white px-6 ${useLocation().pathname === "/request-demo" && "hidden"} capitalize py-2 rounded-sm border-none`} onClick={() => navigate('/request-demo')}>request demo</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
