import Logo from 'assets/repairhive logo/Asset 12.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import { useEffect } from 'react'

const Auth = () => {
  const navigate = useNavigate()

  const now = new Date()

  const handleAuthentication = async ({ email, password }) => {
    const expirationTime = new Date(new Date().getTime() + 10000 * 60 * 24);
    toast.promise(axios
      .post(`${process.env.REACT_APP_API}/auth/login`, {
        email,
        password
      }), {
      pending: 'Logging in',
      success: {
        render({ data: { data } }) {
          Cookies.set(process.env.REACT_APP_COOKIE_KEY, JSON.stringify(data), { expires: expirationTime })
          const { role } = data
          if (role === 'engineer') {
            return navigate('/engineer', { replace: true })
          } else {
            return navigate('/author', { replace: true })
          }
        }
      },
      error: 'Login failed'
    })
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required'),
      password: Yup.string().required('Password is required')
    }),
    onSubmit: (values) => handleAuthentication(values)
  })

  useEffect(() => {
    const _user = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
    if (_user) {
      const user = JSON.parse(_user)
      if (user & user['role']) {
        const { role } = user
        if (role === 'engineer') {
          navigate('/engineer', { replace: true })
        } else {
          navigate('/author', { replace: true })
        }
      }
    }
    return () => { };
  }, []);

  return (
    <div className='w-screen h-screen bg-blackshade flex flex-col justify-center items-center'>
      <div className='w-full max-w-sm px-6 pb-20 m-auto mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800'>
        <div className='flex flex-col items-center space-y-2 mx-auto mb-4'>
          <img className='w-3/12 h-3/12' src={Logo} alt='' />
          <h4 className='font-semibold text-2xl tracking-widest'>Repair<span className='text-blue'>Hive</span></h4>
        </div>
        <div className='mt-6'>
          <div>
            <label htmlFor='username' className='block text-sm text-gray-800 dark:text-gray-200'>
              Username
            </label>
            <input
              name='email'
              type='text'
              className='block w-full px-4 py-2 mt-2 text-gray-300 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
              placeholder='example@example.com'
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <div className='mt-4'>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm text-gray-800 dark:text-gray-200'>
                Password
              </label>
              <a href='#!' className='text-xs text-gray-600 dark:text-gray-400 hover:underline'>
                Forget Password?
              </a>
            </div>

            <input
              name='password'
              type='password'
              className='block w-full px-4 py-2 mt-2 text-gray-300 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40'
              placeholder='password'
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className='bg-redshade text-white rounded-sm px-5 py-2 opacity-50 mt-2'>
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className='mt-6'>
            <button
              className='bg-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full'
              type='submit'
              onClick={() => formik.handleSubmit()}
            >Sign in
            </button>
          </div>
        </div>
      </div>
      <div className='absolute w-full bottom-0 text-center py-[2px]'>
        <h4 className='text-white tracking-widest text-sm leading-6'>© {now.getFullYear()} Repair Hive - Developed by Innovation Consortium</h4>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Auth
