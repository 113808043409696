import React from 'react'
import _Logo from 'assets/repairhive logo/repairhaive-white-alt icon.png'
import __Logo from 'assets/repairhive logo/IC-white-logo 1.png'
const now = new Date()

const Footer = () => {
  return (
    <div>
    <div className='flex flex-row bg-[#242E42]' id='contact-us'>
      <div className='w-[90%] lg:w-[82%] mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-1 grid-flow-row-dense'>
        <div className='w-full space-y-2 py-8 flex flex-col justify-center'>
          <img src={_Logo} className='h-2/3 w-2/3' alt='logo' />
          <h4 className='text-md font-extralight text-gray-300 leading-8'>
            Our comprehensive knowledge-base, coupled with advanced repair and maintenance
            task management, empowers your business with seamless operations and reduced
            downtime
          </h4>
        </div>
        <div className='w-full space-y-2 py-8 flex flex-col justify-center'>
          <img src={__Logo} className='h-1/3 w-1/3' alt='logo' />
          <h4 className='text-md font-extralight text-gray-300 leading-8'>
            Our comprehensive knowledge-base, coupled with advanced repair and maintenance
            task management, empowers your business with seamless operations and reduced
            downtime
          </h4>
        </div>
        <div className='w-full space-y-2 py-8 flex flex-col justify-center'>
          <h4 className='text-gray-300 text-lg capitalize font-semibold leading-10'>
            capabilities
          </h4>
          <ul className='space-y-2'>
            <li className='text-gray-300 capitalize font-extralight'>task management</li>
            <li className='text-gray-300 capitalize font-extralight'>knowledge base</li>
            <li className='text-gray-300 capitalize font-extralight'>maintenance insights</li>
            <li className='text-gray-300 capitalize font-extralight'>advanced reporting</li>
          </ul>
        </div>
        <div className='w-full space-y-2 flex flex-col justify-center'>
          <h4 className='text-gray-300 text-lg capitalize font-semibold leading-10'>
            contact us
          </h4>
          <ul className='space-y-2'>
            <li className='text-gray-300 capitalize font-extralight'>
              Plot 723, Nvule Lane,Bbuto,
            </li>
            <li className='text-gray-300 capitalize font-extralight'>
              Bweyogerere Industrial Park, Uganda
            </li>
            <li className='text-gray-300 capitalize font-extralight'>
              Phone: +256-753-434679
            </li>
            <li className='text-gray-300 capitalize font-extralight'>
              E-Mail: support@repairhive.app
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className='text-center py-1 bg-[#242E42]'>
      <span className='tracking-wider text-[10px] text-white'>
        © {now.getFullYear()} RepairHive. All Rights Reserved.
      </span>
    </div>
  </div>
  )
}

export default Footer
