export function filterDataWithParams(data, params) {
  if (data && Array.isArray(data) && params && typeof params === 'object') {
    const filteredData = data.filter(item => {
      for (const key in params) {
        if (item[key] !== params[key]) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  } else {
    return [];
  }
}


export function crossFilter2Arrays(arr1, arr2, params) {
  if (arr1 && Array.isArray(arr1) && arr2 && Array.isArray(arr2) && params && typeof params === 'object') {
    let _filtered = []
    arr1.forEach(f => {
      _filtered.push(arr2.find(q => q[Object.keys(params)[0]] === f[Object.values(params)[0]]))
    })
    return _filtered
  } else {
    return []
  }
}
