const Card = ({ url, name }) => {
  return (
    <div className='w-11/12 rounded-lg shadow-lg absolute opacity-80 transition duration-300 ease-in-out hover:opacity-100'>
      <img
        className='h-60 w-full rounded-t-lg'
        src={url}
        alt={name}
      />
    </div>
  )
}

export default Card
