import React, { useEffect, useState } from 'react';
import options from 'assets/images/options.svg'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { localDb } from '../../../../sync-db';
import { useLiveQuery } from 'dexie-react-hooks';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import axios from 'axios';
import Select from 'react-select'

const Faults = () => {
  const [toggleActions, settoggleactions] = useState(false)
  const [isEdit, setisEdit] = useState(false)
  const [userId, setuserId] = useState()

  const faults = useLiveQuery(() => localDb._faults.toArray())

  const actionHandler = (action, fault) => {
    settoggleactions(null)
    const { id, name, description, type } = fault
    switch (action) {
      case 'delete':
        deleteHandler(id)
        break;
      case 'edit':
        formik.setFieldValue('id', id)
        formik.setFieldValue('name', name)
        formik.setFieldValue('type', type)
        formik.setFieldValue('description', description)
        setisEdit(true)
        break;
    }
  }

  const submitHandler = async (data) => {
    delete data['id']
    try {
      await localDb._faults.get({ name: data.name }).then(async res => {
        const notification = toast.loading('Adding fault type ...')
        if (!res) {
          await axios.post(`${process.env.REACT_APP_API}/guide/fault/${userId}`, { ...data }).then(async ({ data }) => {
            const { status, ...rest } = data
            await localDb._faults.add({
              ...rest
            }).then(() => {
              formik.resetForm()
              toast.update(notification, { render: "Machine fault created", type: "success", isLoading: false, autoClose: 2000 });
            })
          }).catch((error) => {
            console.log("Error here: " )
            console.log(error)
            toast.update(notification, { render: "Error occured while creating machine fault", type: "error", isLoading: false, autoClose: 3000 });
          })
        } else {
          toast.update(notification, { render: "Machine fault already exists", type: "error", isLoading: false, autoClose: 3000 });
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const editHandler = async data => {
    try {
      const notification = toast.loading('Editing fault type ...')
      await axios.put(`${process.env.REACT_APP_API}/guide/fault/?id=${data.id}`, { ...data }).then(async () => {
        const { id, ...rest } = data
        await localDb._faults.update(id, {
          ...rest
        }).then(() => {
          cauncelEditHandler()
          toast.update(notification, { render: "Machine fault updated", type: "success", isLoading: false, autoClose: 3000 });
        })
      }).catch(() => {
        toast.update(notification, { render: "Error occured while updating machine fault", type: "error", isLoading: false, autoClose: 3000 });
      })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteHandler = async id => {
    const notification = toast.loading('Deleting fault type ...')
    await axios.delete(`${process.env.REACT_APP_API}/guide/fault/?id=${id}`).then(async () => {
      await localDb._faults.where({ id: id }).delete().then(() => {
        toast.update(notification, { render: "Machine fault deleted", type: "success", isLoading: false, autoClose: 3000 });
      })
    }).catch((err) => {
      toast.update(notification, { render: "Error occured while deleting machine fault", type: "error", isLoading: false, autoClose: 3000 });
    })
  }

  const cauncelEditHandler = () => {
    setisEdit(false)
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      type: '',
      description: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      type: Yup.string().required(),
      description: Yup.string().required()
    }),
    onSubmit: (values) => isEdit ? editHandler(values) : submitHandler(values)
  })

  useEffect(() => {
    const _user = Cookies.get(process.env.REACT_APP_COOKIE_KEY)
    if (_user) {
      const { id } = JSON.parse(_user)
      setuserId(id)
    }
    return () => { };
  }, []);

  const faultTypes = [{label: 'General', value: 'general'}, {label: 'Mechanical', value: 'mechanical'}, {label: 'Electrical', value: 'electrical'}]

  return (
    <div className='w-full h-[calc(100vh-65px)] grid grid-cols-3 gap-4 px-3'>
      <div className='bg-white rounded-sm p-8 space-y-8 my-3'>
        <div className='block'>
          <label className='block text-sm text-gray-600 capitalize font-semibold'>
            fault name
          </label>
          <input
            name='name'
            type='text'
            className={`block w-full px-4 py-2 mt-1 text-gray-300 bg-white border rounded-lg focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 ${formik.touched.name && formik.errors.name && `border border-redshade`}`}
            placeholder="Fault's name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </div>
        <div className='block my-4'>
          <label className='block text-sm text-gray-600 capitalize font-semibold'>
            fault type
          </label>
          <Select options={faultTypes} onChange={({ value }) => formik.setFieldValue('type', value)} placeholder="Fault type" name='type' className={`block w-full
   focus:outline-none focus:ring focus:ring-opacity-40 mt-1 ${formik.touched.type && formik.errors.type && `border border-redshade`}`} isSearchable={false} />
        </div>
        <div className='block my-4'>
          <label className='block text-sm text-gray-600 capitalize font-semibold'>
            description
          </label>
          <textarea className={`border border-gray-300 rounded-md w-full h-24 px-2 py-3 ${formik.touched.description && formik.errors.description && `border border-redshade`}`} placeholder="Fault's description" name='description' onChange={formik.handleChange} value={formik.values.description} />
        </div>
        {
          isEdit ? <div className='grid grid-cols-2 gap-4'>
            <div className='container'>
              <button
                className='bg-blue text-white font-bold py-2 px-8 rounded capitalize'
                type='submit'
                onClick={() => formik.handleSubmit()}
              >Save edit
              </button>
            </div>
            <div className='container'>
              <button
                className='bg-redshade text-white font-bold py-2 px-8 rounded capitalize'
                type='submit'
                onClick={() => cauncelEditHandler()}
              >cancel
              </button>
            </div>
          </div> : <div className='container'>
            <button
              className='bg-blue text-white font-bold py-2 px-8 rounded capitalize'
              type='submit'
              onClick={() => formik.handleSubmit()}
            >add fault
            </button>
          </div>
        }
      </div>
      <div className='col-span-2 bg-white rounded-sm px-4 overflow-y-hidden relative my-3'>
        <div className='grid grid-cols-10 gap-4 px-4 py-4 border-b border-gray-200 sticky'>
          <div className='container col-span-2'>
            <div className='inline-flex space-x-4'>
              <input type='checkbox' className='h-4 w-4' />
              <span className='text-sm text-gray-600 capitalize font-semibold tracking-wider'>name</span>
            </div>
          </div>
          <div className='container col-span-2'>
            <span className='text-sm text-gray-600 capitalize font-semibold tracking-wider'>fault type</span>
          </div>
          <div className='container col-span-5'>
            <span className='text-sm text-gray-600 capitalize font-semibold tracking-wider'>description</span>
          </div>
          <div className='container'>
            <span className='text-sm text-gray-600 capitalize font-semibold tracking-wider'>action</span>
          </div>
        </div>
        {
          (faults && faults.length > 0) ? faults.map((fault, index) => <div className={`${index % 2 === 0 && `bg-gray-100`} grid grid-cols-10 gap-4 px-4`} >
            <div className='container col-span-2 py-6'>
              <div className='inline-flex space-x-4'>
                <input type='checkbox' className='h-4 w-4' />
                <span className='text-sm text-gray-600 capitalize tracking-wider'>{fault.name}</span>
              </div>
            </div>
            <div className='container col-span-2 py-6' onClick={() => settoggleactions(null)}>
              <span className='text-sm text-gray-600 capitalize tracking-wider'>{fault.type}</span>
            </div>
            <div className='container col-span-5 py-6' onClick={() => settoggleactions(null)}>
              <span className='text-sm text-gray-600 capitalize tracking-wider'>{fault.description}</span>
            </div>
            <div className='container py-6'>
              <div className='relative'>
                <img src={options} className='cursor-pointer w-4' onClick={() => settoggleactions(index)} />
                <ul className={`absolute z-10 shadow-md bg-white py-3 ${toggleActions !== index && `hidden`}`}>
                  <li className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm' onClick={() => actionHandler('edit', fault)}>edit</li>
                  <li className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm' onClick={() => actionHandler('delete', fault)}>delete</li>
                </ul>
              </div>
            </div>
          </div>) : <div className='w-full h-full flex justify-center items-center text-gray-600 font-semibold capitalize'>No machine faults found</div>
        }
      </div>
    </div>
  );
}

export default Faults;
