import HeaderNav from 'components/header-nav'
import SideNav from 'components/common/side-nav'
import { useState, useEffect } from 'react'
import authorsNavRoutes from 'routes/author'
import { CreateGuide, Dashboard, Guides, Media, Settings, Users } from 'components/author'
import { useNavigate } from 'react-router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'
import { dataIngestor } from '../helpers/data-ingestor'
import Faults from '../components/author/components/machine/faults'
import Departments from '../components/author/components/machine/departments'
import Machine from '../components/author/components/machine/machines'
import MachineParts from '../components/author/components/machine/machine-parts'

const Author = () => {
  const [activeRoute, setactiveRoute] = useState()
  const [activeChildRoute, setactiveChildRoute] = useState()
  const [user, setUser] = useState();
  const navigate = useNavigate()



  useEffect(() => {
    (async () => {
      const user = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
      if (!user) {
        navigate('/auth', { replace: true })
      } else {
        const _user = JSON.parse(user)
        setUser(_user)
        setactiveRoute(authorsNavRoutes[0]['label'])

        // Trigger the background sync
        const { id } = _user
        setInterval(() => {
          const worker = new Worker(`${process.env.PUBLIC_URL}/sync-script.mjs`)
          worker.postMessage({ action: 'author-remote-sync', remote: process.env.REACT_APP_API, id: id });
          worker.onmessage = event => {
            const { data } = event
            // ingest the synced data
            dataIngestor(data)
          };
        }, 30 * 1000); //LocalDB gets synced with the remote after every 30 seconds
      }
    })()
    return () => { }
  }, [])

  return (
    <div className='w-screen h-screen bg-whitebg flex flex-row flex-1'>
      <div className='hidden md:block lg:block md:basis-60 lg:basis-60 relative'>
        <SideNav user={user} routes={authorsNavRoutes} switchRoute={setactiveRoute} route={activeRoute} childRouteSwitch={setactiveChildRoute} childRoute={activeChildRoute} />
      </div>
      <div className='grow h-full flex flex-col'>
        <div className='basis-4 z-20'>
          {user && <HeaderNav user={user} route={activeRoute} activeRoute={activeRoute} child={activeChildRoute} switchRoute={setactiveRoute} />}
        </div>
        <div className='w-full flex flex-col overflow-y-auto z-10'>
          {activeRoute === 'Dashboard' ? (
            <Dashboard switchRoute={setactiveRoute} />
          ) : activeRoute === 'Create Guide' ? (
            <CreateGuide />
          ) : activeRoute === 'Guides' ? (
            <Guides setactiveRoute={setactiveRoute} setchildroute={setactiveChildRoute} />
          ) : activeRoute === 'Media' ? (
            <Media />
          ) : activeRoute === 'Users' ? (
            <Users />
          ) : activeRoute === 'Settings' ? (
            <Settings />
          ) : (activeRoute === 'Machines' && activeChildRoute === '') ? (
            <Machine />
          ) : (activeRoute === 'Machines' && activeChildRoute === 'faults') ? (
            <Faults />
          ) : (activeRoute === 'Machines' && activeChildRoute === 'departments') ? (
            <Departments />
          ) : (activeRoute === 'Machines' && activeChildRoute === 'machines') ? (
            <Machine />
          ) : (activeRoute === 'Machines' && activeChildRoute === 'machine parts') ? (
            <MachineParts />
          ) : (activeRoute === 'Guide' && activeChildRoute === 'edit') ? (
            <CreateGuide edit={true} />
          ) : (
            <Dashboard />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Author
