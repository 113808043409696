import React from 'react';
import { formatDate } from '../../helpers/data-format';
import { capitalizeFirstLetter } from '../../helpers/capitalize-first-letter';
import timer from 'assets/images/timer.svg'
import { useNavigate } from 'react-router';

const GuideCard = ({ guide }) => {
  const navigate = useNavigate()

  if (guide) {
    return <div className='border border-gray-200 rounded-md h-64 grid grid-rows-6 gap-2 rounded-b-md'>
      <div className='row-span-4 cursor-pointer' onClick={() => navigate(`/repertory/${guide.machine}/${guide.fault}/${guide.title}`)}>
        <img src={guide.featuredimage} className='h-full w-full' />
      </div>
      <div className='row-span-2 grid grid-row-4 grap-2 px-2'>
        <div className='row-span-3 text-md'>
          <span className='line-clamp-2 text-sm leading-6 font-normal cursor-pointer'>{capitalizeFirstLetter(guide.title)}</span>
        </div>
        <div className='grid grid-cols-6 gap-4'>
          <div className='col-span-5 cursor-pointer'>
            <span className='line-clamp-1 flex flex-row items-center'>
              <img src={timer} className='w-4 h-4' />
              <span className='text-gray-300 capitalize text-sm line-clamp-1 mx-1'>{formatDate(guide.createdAt)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  }
}

export default GuideCard;
