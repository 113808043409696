import { localDb } from 'sync-db';

export async function dataIngestor(_data) {
  const { key, data } = _data
  switch (key) {
    case 'synced-user':
      await purgeAndUpdateLocalStorage('_users', 'email', data)
      break
    case 'synced-departments':
      await purgeAndUpdateLocalStorage('_departments', 'id', data)
      break
    case 'synced-machines':
      await purgeAndUpdateLocalStorage('_machines', 'name', data)
      break
    case 'synced-machine-parts':
      await purgeAndUpdateLocalStorage('_machineParts', 'name', data)
      break;
    case 'synced-media':
      await purgeAndUpdateLocalStorage('_medias', 'name', data)
      break;
    case 'synced-faults':
      await purgeAndUpdateLocalStorage('_faults', 'name', data)
      break;
    case 'synced-guides':
      await purgeAndUpdateLocalStorage('_guides', 'title', data)
      break;
    default:
      break;
  }

  async function purgeAndUpdateLocalStorage(tableName, tableKey, data) {
    try {
      if (!tableName || !tableKey || !data) {
        throw new Error('Table name, Table key or Table data is not specified')
      }
      const records = await localDb[tableName].toArray()
      // purge already purged data from the remote
      await records.forEach(async record => {
        if (record) {
          if (!data.some(t => t[tableKey] === record[tableKey])) {
            await localDb[tableName].where({ [tableKey]: record[tableKey] }).delete()
          }
        }
      })
      // update and insert new records
      data.forEach(async c => {
        if (c) {
          await localDb[tableName].get({ [tableKey]: c[tableKey] }).then(async record => {
            if (record) {
              const id = record[tableKey]
              delete record[tableKey]
              await localDb[tableName].update(id, { ...record })
            } else {
              await localDb[tableName].add({ ...c })
            }
          })
        }
      })
    } catch (error) {
      throw new Error("Error while syncing local storage ", error)
    }
  }
}
