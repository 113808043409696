import React, { useEffect, useState } from 'react';
import Header from 'components/repository/header'
import Footer from 'components/landpage/footer'
import { useNavigate } from 'react-router';
import { localDb } from '../../sync-db';
import { useLiveQuery } from 'dexie-react-hooks';
import { crossFilter2Arrays, filterDataWithParams } from '../../helpers/data-filter';

const Repository = () => {
  const [mechanicalguides, setmechanicalguides] = useState()
  const [electricalguides, setelectricalguides] = useState()
  const [generalguides, setgeneralguides] = useState()
  const faults = useLiveQuery(async () => await localDb._faults.toArray())
  const guides = useLiveQuery(async () => await localDb._faults.toArray())
  const machines = useLiveQuery(async () => await localDb._machines.toArray())
  const departments = useLiveQuery(async () => await localDb._departments.toArray())
  const navigate = useNavigate()

  useEffect(() => {
    const mechanical = faults && filterDataWithParams(faults, {type: 'mechanical'})
    const electrical = faults && filterDataWithParams(faults, {type: 'electrical'})
    const general = faults && filterDataWithParams(faults, {type: 'general'})
    const _meachinalguides = crossFilter2Arrays(mechanical, guides, {fault: 'name'})
    const _electricalguides = crossFilter2Arrays(electrical, guides, {fault: 'name'})
    const _generalguides = crossFilter2Arrays(general, guides, {fault: 'name'})
    _meachinalguides && setmechanicalguides(_meachinalguides.length)
    _electricalguides && setelectricalguides(_electricalguides.length)
    _generalguides && setgeneralguides(_generalguides.length)
    return () => {
      setelectricalguides(null)
      setgeneralguides(null)
      setmechanicalguides(null)
    };
  }, [guides, faults]);

  return (
    <div className='w-screen h-screen'>
      <Header />
      <div className='flex flex-row mb-16'>
        <div className='lg:basis-80 basis-0 ' />
        <div className='grow flex flex-col lg:pt-0'>
          <div className='container py-8'>
            <span className='text-xl font-black capitalize tracking-wider'>guides repertory</span>
          </div>
          <div className="w-full h-64 border relative bg-repository-banner bg-cover bg-no-repeat mb-2">
            <div className='w-full h-full flex justify-center items-center cursor-pointer bg-opacity-60 bg-blackshade relative'>
              <span className='text-white font-semibold text-3xl absolute tracking-widest'>
                Your number one repair knowledge base
              </span>
            </div>
            {/* <span className='text-white absolute text-xl capitalize bottom-4 right-8'>your go to repair guides repository</span> */}
          </div>
          <div className="flex items-center my-4">
            <hr className="flex-grow" />
            <span className="px-8 text-gray-600 tracking-wider">Guides by fault type</span>
            <hr className="flex-grow" />
          </div>
          <div className='container grid grid-cols-3 gap-4 my-4'>
            <div className='w-full h-64 border relative bg-general bg-cover bg-no-repeat cursor-pointer' onClick={() => navigate('/repertory/category/general')}>
              <span className='absolute top-0 right-0 bg-redshade text-white px-4 capitalize text-sm shadow-md'>general</span>
              <div className='w-full h-full flex justify-center items-center hover:bg-opacity-60 hover:bg-blackshade'>
                <span className='text-white text-3xl font-semibold capitalize'>{generalguides && generalguides} guides</span>
              </div>
            </div>
            <div className='w-full h-64 border relative bg-mechanical bg-cover bg-no-repeat cursor-pointer' onClick={() => navigate('/repertory/category/mechanical')}>
              <span className='absolute top-0 right-0 bg-greenshade text-white px-4 capitalize text-sm shadow-md'>mechanical</span>
              <div className='w-full h-full flex justify-center items-center cursor-pointer hover:bg-opacity-60 hover:bg-blackshade'>
                <span className='text-white text-3xl font-semibold capitalize'>{mechanicalguides && mechanicalguides} guides</span>
              </div>
            </div>
            <div className='w-full h-64 border relative bg-electrical bg-cover bg-no-repeat cursor-pointer' onClick={() => navigate('/repertory/category/electrical')}>
              <span className='absolute top-0 right-0 bg-blue text-white px-4 capitalize text-sm shadow-md'>electrical</span>
              <div className='w-full h-full flex justify-center items-center cursor-pointer hover:bg-opacity-60 hover:bg-blackshade'>
                <span className='text-white text-3xl font-semibold capitalize'>{electricalguides && electricalguides} guides</span>
              </div>
            </div>
          </div>
          <div className="flex items-center my-4">
            <hr className="flex-grow" />
            <span className="px-8 text-gray-600 tracking-wider">Guidies by faults</span>
            <hr className="flex-grow" />
          </div>
          <div className='container grid grid-cols-4 gap-6 my-4'>
            {
              faults && faults.length > 0 && faults.map(({ name }, index) => <div key={index} className='bg-gray-100 h-16 flex justify-start items-center cursor-pointer hover:scale-105' onClick={() => navigate(`/repertory/faults/${name}`)}>
                <span className='font-md capitalize mx-4'>
                  {name}
                </span>
              </div>)
            }
          </div>
          <div className="flex items-center my-4">
            <hr className="flex-grow" />
            <span className="px-8 text-gray-600 tracking-wider">Guidies by machines</span>
            <hr className="flex-grow" />
          </div>
          <div className='container grid grid-cols-4 gap-6 my-4'>
            {
              machines && machines.length > 0 && machines.map(({ name }, index) => <div key={index} className='px-4 bg-gray-100 h-16 flex justify-start items-center cursor-pointer hover:scale-105' onClick={() => navigate(`/repertory/machines/${name}`)}>
                <span className='font-md capitalize'>
                  {name}
                </span>
              </div>)
            }
          </div>
          <div className="flex items-center my-4">
            <hr className="flex-grow" />
            <span className="px-8 text-gray-600 tracking-wider">Guidies by department</span>
            <hr className="flex-grow" />
          </div>
          <div className='container grid grid-cols-4 gap-6 my-4'>
            {
              departments && departments.length > 0 && departments.map(({ name }, index) => <div key={index} className='px-4 bg-gray-100 h-16 flex justify-start items-center cursor-pointer hover:scale-105' onClick={() => navigate(`/repertory/departments/${name}`)}>
                <span className='font-md capitalize'>
                  {name}
                </span>
              </div>)
            }
          </div>
        </div>
        <div className='lg:basis-80 basis-0' />
      </div>
      <Footer />
    </div>
  );
}

export default Repository;
