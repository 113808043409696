import Author from 'pages/author'
import Auth from 'pages/auth'
import {Route, Routes} from 'react-router'
import Engineer from 'pages/engineer'
import Protected from './routes/protected-route'
import HomePage from './pages/home-page'
import RequestDemo from './pages/request-demo'
import GuidesRepo from 'pages/repo/guides-repo'
import FaultsCollection from 'pages/repo/faults-collection'
import GuidesCollection from 'pages/repo/guides-collection'
import Repo from 'pages/repo/repo'
import Repository from './pages/repository'
import Categories from './pages/repository/categories'
import Machines from './pages/repository/machines'
import Faults from './pages/repository/faults'
import Departments from './pages/repository/departments'
import Guide from './pages/repository/guide'

export default function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/request-demo' element={<RequestDemo />} />
        <Route path='/repertory' element={<Repository />} />
        <Route path='/repertory/category/:name' element={<Categories/>} />
        <Route path='/repertory/machines/:name' element={<Machines/>} />
        <Route path='/repertory/faults/:name' element={<Faults/>} />
        <Route path='/repertory/departments/:name' element={<Departments/>} />
        <Route path='/repertory/:machine/:fault/:title' element={<Guide/>} />
        <Route path='/auth' element={<Auth />} />
        <Route
          path='/author'
          element={
            <Protected>
              <Author />
            </Protected>
          }
        />
        <Route
          path='/engineer'
          element={
            <Protected>

            </Protected>
          }
        />
      </Routes>
    </>
  )
}

