import React from 'react'
import {useNavigate} from 'react-router'
import Cookies from 'js-cookie'

import logout from 'assets/images/logout.svg'
import cog from 'assets/images/cog.svg'
import {
  TEDropdown,
  TEDropdownToggle,
  TEDropdownMenu,
  TEDropdownItem,
  TERipple
} from 'tw-elements-react'

const author_pro_pic =
  'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80'

const Accountandbell = ({user}) => {
  const navigate = useNavigate()

  const logoutHandler = () => {
    Cookies.remove(process.env.REACT_APP_COOKIE_KEY)
    navigate('/auth', {replace: true})
  }
  return (
    <div className='grow flex flex-row items-center justify-end inset-x-0 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center'>
      <div className='px-4'>
        <svg className='w-6 h-6' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <div className='flex flex-row items-center'>
        <TEDropdown className='flex justify-center'>
          <TERipple rippleColor='light'>
            <TEDropdownToggle
              tag='a'
              className='flex items-center whitespace-nowrap pointer-events-auto cursor-pointer p-1 text-xs font-medium uppercase leading-normal
             text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out rounded-full
              '
            >
              <img
                src={author_pro_pic}
                className='w-10 h-10 rounded-full object-cover'
                alt='avatar'
              />
            </TEDropdownToggle>
          </TERipple>

          <TEDropdownMenu className='px-2 py-3 mt-2'>
            <TEDropdownItem>
              <span className=' w-full min-w-[240px] block cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm text-left font-normal '>
                <span class='block truncate font-bold text-lg'>{user.name}</span>
                <span class='block truncate text-sm font-medium'>{user.email}</span>
              </span>
            </TEDropdownItem>

            <TEDropdownItem>
              <button
                onClick={() => switchRoute('Settings')}
                className=' w-full hover:bg-blue/20 min-w-[240px] flex cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm text-left font-normal '
              >
                <img className='mr-2 h-4 w-4' src={cog} alt='' />
                settings
              </button>
            </TEDropdownItem>
            <TEDropdownItem>
              <button
                onClick={() => logoutHandler()}
                className='w-full hover:bg-blue/20  min-w-[160px] flex cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm text-left font-normal '
              >
                <img className='mr-2 h-4 w-4' src={logout} alt='' />
                Sign out
              </button>
            </TEDropdownItem>
          </TEDropdownMenu>
        </TEDropdown>
      </div>
    </div>
  )
}

export default Accountandbell
