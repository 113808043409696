import React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { localDb } from '../../sync-db'


const Dashboard = ({ switchRoute }) => {
  const departments = useLiveQuery(async () => await localDb._departments.toArray())
  const machines = useLiveQuery(async () => await localDb._machines.toArray())
  return (
    <>
      <div className='lg:p-4 md:p-4 sm:h-auto lg:h-[150px] md:h-[150px] sticky top-2'>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 w-full'>
          {departments && departments.map(({ name }, index) => {
            const departmentCount = machines ? machines.filter(m => m.department === name) : []
            return <div key={index} className='bg-white rounded-xl px-8 py-4 z-10'>
              <span className='text-md text-gray-300'>{name}</span>
              <h2 className='text-3xl font-bold mt-2 text-blue'>{departmentCount.length}</h2>
            </div>
          })}
        </div>
      </div>
      <div className='sm:h-auto lg:h-[calc(100vh-150px)] md:h-[calc(100vh-150px)] md:px-4 lg:px-4 w-full'>
        <div className='w-full h-full flex justify-center items-center text-gray-600 font-semibold capitalize'>advanced anayltics here</div>
      </div>
    </>
  )
}

export default Dashboard
