import React, { Fragment, useEffect, useState } from 'react';
import Header from 'components/repository/header'
import Footer from 'components/landpage/footer'
import { localDb } from '../../sync-db';
import { useLiveQuery } from 'dexie-react-hooks';
import GuideCard from '../../components/repository/guide-card';
import { useNavigate, useParams } from 'react-router';
import { crossFilter2Arrays, filterDataWithParams } from '../../helpers/data-filter';

const Categories = () => {
  const [faults, setfaults] = useState()
  const [guides, setguides] = useState()
  const _raw = useLiveQuery(async () => await localDb._guides.toArray())
  const raw = useLiveQuery(async () => await localDb._faults.toArray())
  const { name } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const _faults = raw && filterDataWithParams(raw, {type: name})
    setfaults(_faults && _faults)
    return () => {
      setfaults(null)
    };
  }, [raw, name]);

  useEffect(() => {
    const _guides = crossFilter2Arrays(faults, _raw, {fault: 'name'})
    setguides(_guides && _guides)
    return () => {
      setguides(null)
    };
  }, [faults, _raw]);

  return (
    <div className='w-screen h-screen'>
      <Header />
      <div className='flex flex-row mb-16'>
        <div className='lg:basis-80 basis-0 ' />
        <div className='grow flex flex-col lg:pt-0'>
          <div className='container py-8'>
            <span className='text-md font-black capitalize cursor-pointer' onClick={() => navigate('/repertory')}>guides</span>
            {name && <Fragment>
              <span className='mx-2 font-light text-gray-300'>&#10095;</span>
              <span className='text-md font-black capitalize cursor-pointer' onClick={() => navigate('/repertory')}>categories</span>
              <span className='mx-2 font-light text-gray-300'>&#10095;</span>
              <span className='text-md font-black capitalize'>{name}</span>
            </Fragment>}
          </div>
          <div className={`w-full h-64 border bg-opacity-90 bg-no-repeat mb-2 bg-cover ${name === 'general' ? `bg-general` : name === 'electrical' ? `bg-electrical` : name === 'mechanical' ? `bg-mechanical` : ``}`}>
          <div className='w-full h-full flex justify-center items-center cursor-pointer bg-opacity-60 bg-blackshade relative'>
              <span className='text-white font-semibold text-3xl absolute tracking-widest'>
                {`All your ${name} guidance in one place`}
              </span>
          </div>
            {/* <span className='text-white absolute text-xl capitalize bottom-4 right-8'>your go to repair guides repository</span> */}
          </div>
          <div className='container py-1 border-b mt-8'>
            <span className='text-lg font-black capitalize'>faults ({faults && faults.length})</span>
          </div>
          <div className='container grid grid-cols-4 gap-6 my-4'>
            {
              faults && faults.length > 0 && faults.map(({name}, index) => <div key={index} className='px-4 bg-gray-100 h-16 flex justify-start items-center cursor-pointer hover:scale-105'>
                <span className='font-md capitalize'>
                  {name}
                </span>
              </div>)
            }
          </div>
          <div className='container py-2 border-b mt-8'>
            <span className='text-lg font-black capitalize'>guides ({guides && guides.length})</span>
          </div>
          <div className='container grid grid-cols-4 gap-4 my-4'>
            {guides && guides.length > 0 && guides.map((guide, index) => <GuideCard key={index} guide={guide} />)}
          </div>
        </div>
        <div className='lg:basis-80 basis-0' />
      </div>
      <Footer />
    </div>
  );
}

export default Categories;
