import React, {useState} from 'react'
import Logo from 'assets/repairhive logo/Repairhove-main.png'
import Pc from 'assets/images/resources/pc.png'
import Engineer from 'assets/images/resources/engineer (1).png'
import Book from 'assets/images/resources/book 1.svg'
import BookOpen from 'assets/images/resources/book open.svg'
import Menu from 'assets/images/resources/menu.svg'
import Trend from 'assets/images/resources/trend.svg'
import Calender from 'assets/images/resources/calender.svg'
import Shop from 'assets/images/resources/shop.svg'
import Tick from 'assets/images/resources/tick.svg'
import Workshop from 'assets/images/resources/work shop 1.png'

const features = [
  {
    imgSrc: BookOpen,
    title: 'intelligent Knowledge base',
    description:
      'From troubleshooting to preventive measures, find experts insights at your fingertips'
  },
  {
    imgSrc: Menu,
    title: 'task managment elevated',
    description: 'Priotize task based on urgency, complexity, capacity or available resources.'
  },
  {
    imgSrc: Book,
    title: 'engineer & worker database',
    description: 'Find the right expertise swiftly, ensure your machinery is in competent hands.'
  },
  {
    imgSrc: Trend,
    title: 'predictive maintenance insights',
    description:
      'Data-driven suggestions to address maintenance needs before they snowball into costly problems.'
  },
  {
    imgSrc: Calender,
    title: 'real-time updates',
    description:
      'Receive live update on task progress, maintenance schedules, and engineer availability.'
  },
  {
    imgSrc: Shop,
    title: 'report & feedback forms',
    description:
      "Transform your raw data into actionable insight, empowering you to enhance your machinery's longevity and minimum downtime."
  }
]
import {useNavigate} from 'react-router'
import Header from 'components/landpage/header-x'
import Footer from 'components/landpage/footer'

const HomePage = () => {
  const navigate = useNavigate()
  return (
    <div className='w-screen h-screen overflow-y-auto absolute'>
      <Header />

      <div>
        <div className='flex flex-row' id='home'>
          <div className='lg:basis-48 basis-0 ' />
          <div className='grow flex flex-col lg:flex-row lg:items-center lg:pt-0'>
            <div className='basis-1/2 lg:pr-6 py-10 lg:px-8 bg-white lg:h-[calc(100vh-64px)] pt-4 md:pt-8 lg:pt-0 flex flex-col lg:justify-center space-y-6 lg:space-y-12 '>
              <h3 className='font-bold pb-0 md:pb-16 px-4 lg:pb-0 lg:basis-0 z-20 lg:px-0 text-lg md:text-4xl lg:text-[3rem] lg:leading-[3rem] text-center lg:text-left tracking-widest'>
                Unlock
                <span className='text-blue ml-2'>Efficiency</span>, Precision, and{' '}
                <span className='text-blue'>Seamless Operations</span> in Manufacturing
              </h3>

              <div className='lg:hidden z-10 flex flex-row h-[40vh] md:h-[40vh] overflow-x-clip rotate-180 mb-40 items-baseline justify-center bg-blue'>
                <img src={Engineer} className='h-96  z-10 -rotate-180' />
                <img src={Pc} className='h-60 mb-auto -ml-20 -rotate-180' />
              </div>

              <h5 className='text-center lg:text-left px-4 lg:px-0 z-20 bg-white text-lg text-gray-300'>
                <span className=' text-blue'>RepairHive</span> is your all-in-one Knowledgebase and
                trask managment platform, meticulously crafted to elevate your maintenance processes
                to new heights.
              </h5>
              <div className='px-4 lg:px-0'>
                <button
                  className='w-full lg:w-64 lg:hover:w-72 transition z-10 border-none shadow-sm bg-blue px-12 text-lg py-3 text-white capitalize flex flex-row items-center'
                  onClick={() => navigate('/request-demo')}
                >
                  <span className='grow text-left'>request demo</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='mt-[0.25rem] w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3'
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className='hidden lg:block absolute z-10 lg:basis-1/2 bottom-[25vh] overflow-x-clip lg:overflow-x-visible lg:bottom-0 h-[50vh] bg-blue w-full lg:h-[calc(100vh-64px)] lg:relative'>
              <img
                src={Pc}
                className='absolute lg:bottom-4 bottom-10 lg:w-[32vw] lg:-left-[18vw]'
              />
              <img
                src={Engineer}
                className='absolute bottom-0 lg:bottom-0 lg:right-48 lg:w-3/5 z-10'
              />
            </div>
          </div>
        </div>

        <div
          className='flex flex-wrap w-[95%] lg:w-[80%] mx-auto my-4 lg:my-8 text-center md:text-left'
          id='features'
        >
          {features.map((feature, index) => (
            <div className='w-[100%] md:w-[50%] lg:w-[33.33%] md:p-[.5%] py-[1%] flex' key={index}>
              <div className='flex-grow shadow-lg border-2 border-gray-100 bg-white rounded-md space-y-5 px-10 py-8'>
                <img src={feature.imgSrc} className='w-12 h-12 mx-auto md:mx-0 ' alt='alt' />
                <h4 className='capitalize text-lg font-semibold'>{feature.title}</h4>
                <p className='text-md text-gray-300'>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className='flex flex-col md:flex-row ' id='why-us'>
          <div className='absolute md:static w-full hidden md:block md:basis-1/2 h-[350px] md:h-[400px] bg-home bg-no-repeat bg-cover object-none' />
          <div className='z-10 basis-1/2 h-[400px] px-10 py-20 space-y-5 flex flex-col justify-center bg-blue/30 md:bg-blue/30  backdrop-blur'>
            <h5 className='text-2xl font-semibold text-center md:text-left capitalize tracking-wider'>
              why choose us
            </h5>
            <ul className='flex flex-col'>
              <li className='text-xl py-2 inline-flex'>
                <img src={Tick} className='w-8 h-8 mr-4' />
                <span className='text-grey-300 font-light'>Tailored to manufacturing</span>
              </li>
              <li className='text-xl py-2 inline-flex'>
                <img src={Tick} className='w-8 h-8 mr-4' />
                <span className='text-grey-300 font-light'>Expertise on demand</span>
              </li>
              <li className='text-xl py-2 inline-flex'>
                <img src={Tick} className='w-8 h-8 mr-4' />
                <span className='text-grey-300 font-light'>Future Ready</span>
              </li>
            </ul>
          </div>
        </div>

        <div className='mx-auto w-[95%] lg:w-[80%] text-center md:text-left lg:flex flex-row my-8'>
          <div className='w-full text-center lg:text-left lg:basis-1/3 bg-white space-y-2 py-8 flex flex-col justify-center'>
            <h4 className='capitalize text-3xl text-bold'>
              Our Impact
              <br /> <span className='text-blue'>in Industry</span>
            </h4>
          </div>
          <div className='flex flex-col pr-4  grow md:flex-row'>
            <div className='w-full block text-center lg:text-left bg-white space-y-2 py-8'>
              <h4 className='capitalize text-5xl text-bold'>
                95<span className='text-blue'>%</span>
              </h4>
              <h4 className='text-lg text-gray-300'>
                Reduction in technician time spent filling work orders and followups
              </h4>
            </div>
            <div className='w-full block text-center lg:text-left bg-white space-y-2 py-8'>
              <h4 className='capitalize text-5xl text-bold'>
                $50<span className='text-blue'>K</span>
              </h4>
              <h4 className='text-lg text-gray-300'>Savings from avoided production downtime</h4>
            </div>
          </div>
        </div>

        <div
          className='flex flex-col px-[3%] md:flex-row bg-blue h-full py-8 md:py-0 md:h-[450px] lg:h-[600px] bg-opacity-5 relative'
          id='contact-us'
        >
          <h5 className='text-2xl md:hidden px-3 md:px-10 text-center pb-4 font-semibold capitalize tracking-widest'>
            revamp your maintenance approach
          </h5>
          <div className='basis-1/2 relative'>
            <img src={Workshop} className='w-full lg:w-[90%] md:absolute bottom-0' />
          </div>
          <div className='basis-1/2 md:py-10   space-y-5 md:space-y-10 flex flex-col md:justify-center'>
            <h5 className='text-2xl font-semibold capitalize hidden md:block tracking-widest'>
              revamp your maintenance approach
            </h5>
            <h5 className='text-grey-300 tracking-wide'>
              Ready to optimize your manufacturing maintenance and drive efficiency like never
              before? Request a quote for RepairHive and unveil a world of predictive insights, task
              managment solutions, and access to skilled workforce
            </h5>
            <div>
              <button
                className='bg-blue text-white w-full md:w-fit px-6 capitalize py-2 rounded-sm border-none'
                onClick={() => navigate('/request-demo')}
              >
                request demo
              </button>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default HomePage
