import Button from 'components/common/button'
import {useEffect, useState} from 'react'
import add_rounded from 'assets/images/add-rounded.svg'
import AddUserModal from './components/add-user-modal'
import {useLiveQuery} from 'dexie-react-hooks'
import {localDb} from '../../sync-db'
import options from 'assets/images/options.svg'
import Tab from '../common/tab'
import {toast} from 'react-toastify'
import axios from 'axios'

const user_types = ['all', 'engineer', 'author', 'editor', 'machine owner']

const Users = ({setactiveRoute, setchildroute}) => {
  const [activeOption, setActiveOption] = useState()
  const [showModal, setShowModal] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [toggleActions, settoggleactions] = useState(false)
  const [isEdit, setisEdit] = useState(false)

  const [userInitialvalues, setUserInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    password: ''
  })

  const users = useLiveQuery(async () => await localDb._users.toArray())

  const actionHandler = (action, user) => {
    const {id, firstName, lastName, email, role, password} = user
    switch (action) {
      case 'delete':
        deleteHandler(id)
        break
      case 'edit':
        setUserInitialValues({firstName, lastName, email, role, password})
        setShowModal(true)
        setisEdit(true)
        break
    }
    settoggleactions(false)
  }

  const deleteHandler = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API}/user/?id=${id}`)
      .then(async () => {
        await localDb._users
          .where({id: id})
          .delete()
          .then(() => {
            toast.success('User deleted successfully', {autoClose: 3000})
          })
      })
      .catch((err) => {
        console.log(err)
        toast.error('Error occured while deleting user', {autoClose: 3000})
      })
  }

  const filterUsersByRole = (param) => {
    if (users) {
      const filteredUsers =
        param === 'all'
          ? setFilteredUsers(users)
          : users.filter(({role}) => role.toLowerCase() === param.toLowerCase())
      if (filteredUsers) {
        setFilteredUsers(filteredUsers)
      }
    }
  }

  useEffect(() => {
    setActiveOption(user_types[0])
    return () => {}
  }, [])

  useEffect(() => {
    filterUsersByRole(activeOption)
    return () => {}
  }, [users, activeOption])

  return (
    <>
      {showModal && (
        <AddUserModal userInitialvalues={userInitialvalues} isEdit={isEdit} handleclose={setShowModal} />
      )}
      <div className='h-auto p-4 w-full'>
        <div className='w-full h-full flex flex-col bg-white px-8 pt-4 pb-3 overflow-y-auto'>
          <div className='flex justify-between items-center basis-5'>
            <div className='basis-5/6 flex justify-start'>
              <Tab options={user_types} activeOption={activeOption} switchOption={setActiveOption} />
            </div>
            <div className='basis-1/6 justify-between flex flex-row'>
              <Button onClick={() => setShowModal(true)}>
                <span className='capitalize mr-2'>add user</span>
                <img alt='' src={add_rounded} />
              </Button>
            </div>
          </div>
          <div className='w-full flex items-center flex-row border-b border-gray-200 px-6 py-4'>
            <div className='basis-1/3'>
              <h3 className='text-md text-blue text-start font-semibold capitalize'>user</h3>
            </div>
            <div className='basis-1/3'>
              <h3 className='text-md text-blue text-center font-semibold capitalize'>position</h3>
            </div>
          </div>
          {/* users */}
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <div
                key={index}
                className='w-full flex items-center flex-row border-b border-gray-200 px-6 py-4'
              >
                <div className='basis-1/3 flex flex-row items-center'>
                  <img
                    src='https://source.unsplash.com/user/c_v_r'
                    className='object-cover w-12 h-12 rounded-full'
                    alt='avatar'
                  />
                  <h3 className='text-md text-gray-600 ml-3 capitalize'>{`${user.firstName} ${user.lastName}`}</h3>
                </div>
                <div className='basis-1/3'>
                  <h3 className='text-md text-gray-600 text-center capitalize'>{user.role}</h3>
                </div>
                <div className='basis-1/3 flex flex-row justify-end'>
                  <div className='relative'>
                    <img
                      src={options}
                      className='cursor-pointer w-4'
                      onClick={() => settoggleactions(index)}
                    />
                    <ul
                      className={`absolute z-10 shadow-md bg-white py-3 ${
                        toggleActions !== index && `hidden`
                      }`}
                    >
                      <li
                        className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm'
                        onClick={() => actionHandler('edit', user)}
                      >
                        edit
                      </li>
                      <li
                        className='capitalize hover:bg-gray-100 w-full px-2 py-1 cursor-pointer text-sm'
                        onClick={() => actionHandler('delete', user)}
                      >
                        delete
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='bg-warning-300 text-white font-semibold px-3 py-4'>
              <h3 className='capitalize'>{`No ${activeOption} found`}</h3>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Users
