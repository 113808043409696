import { useCallback, useEffect, useState } from 'react'
import Model from '../../common/model'
import Button from '../../common/button'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { toast } from 'react-toastify'
import { localDb } from '../../../sync-db'
import Cookies from 'js-cookie'

const AddMediaModal = ({ handleClose }) => {
  const [files, setFiles] = useState([])
  const [progress, setProgress] = useState(0)
  const [userId, setuserId] = useState('');

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      acceptedFiles.map((file) => {
        setFiles((prevFiles) => {
          return [...prevFiles, file]
        })
      })
    }, []),
    accept: ['application/pdf, image/*']
  })

  const fileChangeHandler = (e) => {
    e.preventDefault()
    const { name, size } = e.target.files[0]
    if (size > 1024) {
      toast.error('File too large, Max file size is 1MB')
      return false
    }
    if (files.includes(({ file }) => file.name === name)) {
      toast.error('File alread exists')
    }
    setFiles((prevState) => {
      return [...prevState, e.target.files[0]]
    })
  }

  const removeFileHandler = (name) => setFiles(files.filter(({ file }) => file.name !== name))
// https://api.cloudinary.com/v1_1/demo/image/upload -X POST --data 'file=sample.jpg&eager=w_400,h_300,c_pad|w_260,h_200,c_crop,g_north&timestamp=173719931&api_key=436464676&signature=a788d68f86a6f868af'
  const uploadHandler = async (e) => {
    const _files = []
    const _uploader = files.map(file => {
      const notification = toast.loading(`Uploading ${file.name}...`)
      const formData = new FormData()
      formData.append('file', file)
      formData.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );
      formData.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
      return axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData).then(({ data }) => {
        const { original_filename, secure_url, resource_type } = data
        localDb._medias.add({
          name: original_filename,
          url: secure_url,
          type: resource_type
        })
        _files.push({ name: original_filename, url: secure_url, type: resource_type })
        toast.update(notification, { render: `${original_filename} uploaded successfully`, type: "success", isLoading: false, autoClose: 3000 });
      }).catch((error) => {
        console.error(error)
        toast.update(notification, { render: `An error occured while uploading ${file.name}`, type: "error", isLoading: false, autoClose: 3000 });
      })
    })
    await axios.all(_uploader).then(async () => {
      setFiles([])
      document.getElementById('_files_to_upload').innerHTML = ''
      await axios.post(`${process.env.REACT_APP_API}/user/${userId}/upload `, {
        files: _files
      }).then(() => {
        toast.success("Files uploaded successfully")
        // toast.update(notification, { render: `Files uploaded successfully`, type: "error", isLoading: false, autoClose: 6000 });
      }).catch(() => {
        // try reading the local db and untill we get a success
      })
    })
  }

  useEffect(() => {
    const _user = Cookies.get(process.env.REACT_APP_COOKIE_KEY)
    if (_user) {
      const { id } = JSON.parse(_user)
      setuserId(id)
    }
    return () => { };
  }, []);

  return (
    <Model handleClose={handleClose}>
      <div className='w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md'>
        <i className='opacity-0'>
          <svg
            className='fill-current w-12 h-12 mb-3 text-blue-700'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z' />
          </svg>
        </i>
        <p className='opacity-0 text-lg text-blue-700'>Drop files to upload</p>
      </div>
      <section className=' overflow-auto p-8 w-full h-full flex flex-col'>
        <div
          className='border-dashed border-2 border-gray-400 py-12 px-10 flex flex-col justify-center items-center cursor-pointer'
          {...getRootProps()}
        >
          <input className='hidden' onChange={(e) => fileChangeHandler(e)} {...getInputProps()} />
          <>
            {isDragActive ? (
              <p className='mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
                Release to drop the files here
              </p>
            ) : (
              <p className='mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
                <span>Drag and drop your</span>&nbsp;
                <span>files here or click to select file</span>
              </p>
            )}
          </>
        </div>
        <h1 className='pt-8 pb-3 font-semibold sm:text-lg text-gray-900'>Files To Upload</h1>
        {files.length > 0 ? (
          <div className='flex flex-wrap w-full' id="_files_to_upload">
            {files.map((file, index) => (
              <div
                key={index}
                className='ml-5 mt-5 h-auto h-max-[120px] w-[120px]  overflow-x-hidden text-center p-2 relative'
              >
                <div
                  className='absolute inset-x-0 top-0 cursor-pointer -right-2 rounded-full bg-redshade w-5 h-5 text-[13px] flex justify-center items-center text-white'
                  onClick={() => removeFileHandler(file.name)}
                >
                  x
                </div>
                {file.type.match('image.*') ? (
                  <img
                    className='mx-auto w-full h-auto h-max-[120px]'
                    src={URL.createObjectURL(file)}
                    alt='no data'
                  />
                ) : (
                  <div className='w-full h-full border overflow-hidden flex items-center justify-center'>
                    {file.name}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <ul id='gallery' className='flex flex-1 flex-wrap -m-1'>
            <li
              id='empty'
              className='h-full w-full text-center flex flex-col justify-center items-center'
            >
              <img
                className='mx-auto w-32'
                src='https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png'
                alt='no data'
              />
              <span className='text-small text-gray-500'>No files selected</span>
            </li>
          </ul>
        )}
      </section>
      <footer className='flex justify-end px-8 pb-8 pt-4'>
        <Button onClick={() => uploadHandler()}>Upload now</Button>
        <button
          className='ml-3 rounded-sm px-3 py-1 hover:bg-gray-300 focus:shadow-outline focus:outline-none'
          onClick={() => handleClose(false)}
        >
          Cancel
        </button>
      </footer>
    </Model>
  )
}

export default AddMediaModal
