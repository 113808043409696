import { useLiveQuery } from 'dexie-react-hooks';
import { Fragment, useEffect, useState } from 'react';
import { localDb } from '../../sync-db';
import { useNavigate, useParams } from 'react-router';
import Header from '../../components/repository/header';
import Footer from '../../components/landpage/footer';
import timer from 'assets/images/timer.svg'

const Guide = () => {
  const [faultdetails, setfaultdetails] = useState()
  const guides = useLiveQuery(async () => await localDb._guides.toArray())
  const faults = useLiveQuery(async () => await localDb._faults.toArray())
  const [guide, setguide] = useState(null)
  const { machine, fault, title } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (title && guides && guides.length > 0) {
      const f = guides && guides.filter(t => t.title === title)
      const t = (faults && f) && faults.filter(x => x.name === f.fault)
      f && setguide(f[0])
      t && setfaultdetails(t[1])
    }
    return () => {
      setguide(null)
      setfaultdetails(null)
    };
  }, [title, guides, faults]);

  return (
    <div className='w-screen h-screen'>
      <Header />
      <div className='flex flex-row mb-16'>
        <div className='lg:basis-80 basis-0 ' />
        <div className='grow flex flex-col lg:pt-0'>
          <div className='container py-4 border-b border-gray-200 my-4'>
            <span className='text-md font-black capitalize cursor-pointer tracking-wider' onClick={() => navigate('/repertory')}>guides</span>
            {machine && <Fragment>
              <span className='mx-2 font-light text-gray-300 tracking-wider'>&#10095;</span>
              <span className='text-md font-black capitalize tracking-wider cursor-pointer' onClick={() => navigate(`/repertory/machines/${machine}`)}>{machine}</span>
            </Fragment>
            }
            {
              fault && <Fragment>
                <span className='mx-2 font-light text-gray-300'>&#10095;</span>
                <span className='text-md font-black capitalize tracking-wider cursor-pointer' onClick={() => navigate(`/repertory/faults/${fault}`)}>{fault}</span>
              </Fragment>
            }
          </div>
          <div className='container border-b py-4 flex flex-row items-center'>
            <img src={(guide && guide.featuredimage) && guide.featuredimage} className='w-24 h-24' />
            <span className='text-xl font-semibold tracking-widest mx-8'>{title && title}</span>
          </div>
          {
            guide && <div className='container my-8 w-full'>
              <p className='tracking-wide' dangerouslySetInnerHTML={{ __html: guide.content }} />
            </div>
          }
          <div className='grid grid-cols-6 gap-4'>
            {/* <div className='col-span-1'>
              {
                fault && <Fragment>
                  {fault.description}
                </Fragment>
              }
            </div> */}
            {/* <div className='container col-span-1'>
              <div className='container border-b py-4 flex flex-row items-center'>
                <img src={(guide && guide.featuredimage) && guide.featuredimage} className='w-24 h-24' />
                <span className='text-xl font-semibold tracking-widest mx-8'>{title && title}</span>
              </div>
              {
                guide && <div className='container my-8 w-full'>
                  <p className='tracking-wide' dangerouslySetInnerHTML={{ __html: guide.content }} />
                </div>
              }
            </div> */}
          </div>
        </div>
        <div className='lg:basis-80 basis-0' />
      </div>
      <Footer />
    </div>
  );
}

export default Guide;
