import ReactPortal from 'components/common/portal'
import {Children, cloneElement, useEffect} from 'react'

const Model = ({handleClose, children}) => {
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === 'Escape' ? handleClose(false) : null)
    document.body.addEventListener('keydown', closeOnEscapeKey)
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [])

  const updateChildrenWithProps = Children.map(children, (child, i) => {
    return cloneElement(child, {
      handleClose: handleClose
    })
  })

  return (
    <ReactPortal wrapperId='react-portal-modal-container'>
      <div className='relative z-[1034]'>
        <div className='fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity' />
        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='bg-gray-500 w-screen sm:px-8 md:px-16 sm:py-8'>
            <div className='container mx-auto max-w-screen-lg h-full'>
              <div className='relative h-full flex flex-col bg-white shadow-xl rounded-md overflow-y-auto'>
                {updateChildrenWithProps}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  )
}

export default Model
